import {i18n} from '../../locale/i18n';
import {useState, useEffect} from 'react';
import {useNavigate, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import requestCustomerNoReminder from '../../actions/requestCustomerNoReminder';
import {GlobalState} from '../../reducers/index';
import DocumentTitle from '../parts/DocumentTitle';
import Header from '../parts/Headers/Reminder';
import showAlert from '../../actions/showAlert';
import {
  checkInputted,
  checkNumbers,
  checkPhoneNumber,
  checkMailAddress,
} from '../../util/validation';

import {RequestCustomerNoResultState} from '../../reducers/requestCustomerNoResult';

import sendEventAnalytics from '../../actions/sendEventAnalytics';
import resetCustomerNoReminder from '../../actions/resetCustomerNoReminder';

import EmailOrSms from '../parts/EmailOrSms';
import WhiteOutButton from '../parts/WhiteOutButton';
import RightAllowLink from '../parts/RightAllowLink';
import LeftAllowLink from '../parts/LeftAllowLink';

import './Reminder.scss';
import './ReminderFailed.scss';
import './ReminderSended.scss';
import '../login/loginAndPassword.scss';
interface DispatchProps {
  sendEvent: typeof sendEventAnalytics;
  callShowAlert: typeof showAlert;
  remindCustomerNo: typeof requestCustomerNoReminder;
  dispatchResetCustomerNoReminder: () => void;
}

type Props = RequestCustomerNoResultState & DispatchProps;

export const CustomerNoReminder = ({
  sendEvent,
  callShowAlert,
  requestCustomerNoCompleted,
  requestCustomerNoLocked,
  requestCustomerNoErrorMessage,
  remindCustomerNo,
  dispatchResetCustomerNoReminder,
  requestCustomerNoDate,
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (requestCustomerNoLocked) {
      callShowAlert({
        title: 'エラー',
        message: requestCustomerNoErrorMessage || '',
        buttons: [
          {
            text: '閉じる',
            onClick: () => {
              dispatchResetCustomerNoReminder();
            },
          },
        ],
      });
    }
  }, [
    requestCustomerNoLocked,
    requestCustomerNoErrorMessage,
    callShowAlert,
    dispatchResetCustomerNoReminder,
  ]);

  const [mailAddressOrPhoneNumber, setMailAddressOrPhoneNumber] =
    useState<string>('');

  const submit = () => {
    sendEvent('CustomerNoReminder', 'customer no reminder button click');

    let message: string | null = null;
    let mailAddress = '';
    let phoneNumber = '';
    if (checkInputted(mailAddressOrPhoneNumber)) {
      message = i18n.t('alert.contactEmpty');
    } else if (
      !checkNumbers(mailAddressOrPhoneNumber) ||
      !checkPhoneNumber(mailAddressOrPhoneNumber)
    ) {
      phoneNumber = mailAddressOrPhoneNumber.replace(/-/g, '');
    } else if (!checkMailAddress(mailAddressOrPhoneNumber)) {
      mailAddress = mailAddressOrPhoneNumber;
    } else {
      message = i18n.t('alert.mailAddressInvalid');
    }

    if (message !== null) {
      sendEvent('CustomerNoReminder', 'customer no reminder validation error');
      callShowAlert({
        title: '入力エラー',
        message,
      });
    } else {
      remindCustomerNo(mailAddress, phoneNumber);
    }
  };

  if (requestCustomerNoCompleted && !requestCustomerNoLocked) {
    return (
      <>
        <Header />
        <main className='login-and-password reminder-sended'>
          <DocumentTitle
            pageNameInTitle={i18n.t('passwordReset.linkCustomerNumberReminder')}
          />
          <div className='contents-inner'>
            <h2>{i18n.t('passwordResetDone.title')}</h2>
            <h3>お客様番号のご案内をお送りしました。</h3>
            <p>数分経ってもメールまたはメッセージ（SMS）が届かない場合は、</p>
            <p>一度所属会社に登録情報の確認をお願いいたします。</p>

            <div className='submit'>
              <WhiteOutButton
                onClick={() => {
                  dispatchResetCustomerNoReminder();
                }}
              >
                {i18n.t('passwordResetDone.resend')}
              </WhiteOutButton>
            </div>

            <LeftAllowLink
              onClick={() => {
                sendEvent('Login', 'login link click');
                navigate('/');
              }}
            >
              {i18n.t('passwordResetDone.linkLogin')}
            </LeftAllowLink>
          </div>
        </main>
      </>
    );
  }

  const descriptions = i18n.t('customerNoReminder.description').split('\n');
  return (
    <>
      <Header />
      <main className='reminder'>
        <DocumentTitle pageNameInTitle='ログイン画面 お客様番号を忘れた方' />
        <div className='contents-inner'>
          <h2>{i18n.t('customerNoReminder.title')}</h2>
          {descriptions.map((description: string) => (
            <p key={description}>{description}</p>
          ))}

          <form>
            <EmailOrSms
              name='mailphone'
              onChange={e => setMailAddressOrPhoneNumber(e.target.value)}
              value={mailAddressOrPhoneNumber}
            />

            <div className='submit'>
              <WhiteOutButton onClick={submit}>
                {i18n.t('button.send')}
              </WhiteOutButton>
            </div>
          </form>

          <NavLink
            to='/password-reminder'
            onClick={() =>
              sendEvent(
                'CustomerNoReminder',
                'customer no reminder login link click'
              )
            }
          >
            <RightAllowLink>
              {i18n.t('customerNoReminder.linkPasswordReminder')}
            </RightAllowLink>
          </NavLink>
          <NavLink
            to='/login'
            onClick={() => sendEvent('Login', 'login link click')}
          >
            <LeftAllowLink>
              {i18n.t('passwordResetDone.linkLogin')}
            </LeftAllowLink>
          </NavLink>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  ...state.requestCustomerNoResult,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  remindCustomerNo: (mailAddress, phoneNumber) =>
    dispatch(requestCustomerNoReminder(mailAddress, phoneNumber)),
  sendEvent: (category, action) =>
    dispatch(sendEventAnalytics(category, action)),
  callShowAlert: config => dispatch(showAlert(config)),
  dispatchResetCustomerNoReminder: () => dispatch(resetCustomerNoReminder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNoReminder);
