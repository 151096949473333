import {AlertConfig} from '../components/parts/Alert';
import ReportError from './reportError';

export const REQUEST_CUSTOMER_NO_REMINDER = 'REQUEST_CUSTOMER_NO_REMINDER';
export const REQUEST_CUSTOMER_NO_REMINDER_SUCCESS =
  'REQUEST_CUSTOMER_NO_REMINDER_SUCCESS';
export const REQUEST_CUSTOMER_NO_REMINDER_ERROR =
  'REQUEST_CUSTOMER_NO_REMINDER_ERROR';
export const REQUEST_CUSTOMER_NO_REMINDER_LOCKED =
  'REQUEST_CUSTOMER_NO_REMINDER_LOCKED';

interface Meta {
  alertConfig: AlertConfig;
}

const requestCustomerNoReminder = (
  mailAddress: string,
  phoneNumber: string,
  meta?: Meta
) => ({
  type: REQUEST_CUSTOMER_NO_REMINDER as typeof REQUEST_CUSTOMER_NO_REMINDER,
  payload: {
    mailAddress,
    phoneNumber,
  },
  meta,
});

interface RequestCustomerNoReminderSuccess {
  type: typeof REQUEST_CUSTOMER_NO_REMINDER_SUCCESS;
}

export type RequestCustomerNoReminderAction =
  | ReturnType<typeof requestCustomerNoReminder>
  | RequestCustomerNoReminderSuccess
  | ReportError<typeof REQUEST_CUSTOMER_NO_REMINDER_ERROR>
  | {
      type: typeof REQUEST_CUSTOMER_NO_REMINDER_LOCKED;
      payload: {message: string};
    };

export default requestCustomerNoReminder;
