import {
  RequestPasswordResetAction,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_ERROR,
  REQUEST_PASSWORD_RESET_LOCKED,
} from '../actions/requestPasswordReset';

import {
  ResetPasswordResetAction,
  RESET_PASSWORD_RESET,
} from '../actions/resetPasswordReset';
import localStorage from '../util/localStorage';

type AuthTokenAction = RequestPasswordResetAction | ResetPasswordResetAction;

export interface ResetPasswordResultState {
  resetPasswordDate: null | Date;
  resetPasswordCompleted: boolean;
  passwordResetSucceeded: boolean;
  passwordResetLocked: boolean;
  passwordResetErrorMessage: string | null;
}

const resetPasswordDate = localStorage.resetPasswordDate;

const initialState: ResetPasswordResultState = {
  resetPasswordDate,
  resetPasswordCompleted: false,
  passwordResetSucceeded: false,
  passwordResetLocked: false,
  passwordResetErrorMessage: null,
};

const resetPasswordResultReducer = (
  state = initialState,
  action: AuthTokenAction
) => {
  switch (action.type) {
    case RESET_PASSWORD_RESET:
      return Object.assign({}, state, {
        resetPasswordCompleted: false,
        passwordResetSucceeded: false,
        passwordResetLocked: false,
        passwordResetErrorMessage: null,
      });

    case REQUEST_PASSWORD_RESET:
      return Object.assign({}, state, {});
    case REQUEST_PASSWORD_RESET_SUCCESS:
      const newResetPasswordDate = new Date();
      localStorage.resetPasswordDate = newResetPasswordDate;
      return Object.assign({}, state, {
        resetPasswordDate: newResetPasswordDate,
        resetPasswordCompleted: true,
        passwordResetSucceeded: true,
      });
    case REQUEST_PASSWORD_RESET_LOCKED:
      return Object.assign({}, state, {
        resetPasswordCompleted: true,
        passwordResetSucceeded: false,
        passwordResetLocked: true,
        passwordResetErrorMessage: action.payload.message,
      });
    case REQUEST_PASSWORD_RESET_ERROR:
      return Object.assign({}, state, {
        resetPasswordCompleted: true,
        passwordResetSucceeded: false,
        passwordResetLocked: false,
        passwordResetErrorMessage: action.payload.message,
      });
    default:
      return state;
  }
};

export default resetPasswordResultReducer;
